import React from 'react';
import PropTypes from 'prop-types';

import {PageWrapper} from '../components/templates';
import LayoutGenerator from '../helpers/layoutGenerator';
import {SearchInputInline} from '../components/organisms';
import {Column, LayoutContainer} from '../components/layouts';
import {Divider, Button} from '../components/common';
import {RCDLStateContext} from '../contexts/RCDLcontext';
import {WithSearch} from '@elastic/react-search-ui';
import {useHomeSearchHandles} from '../contexts/searchContext';

/**
 * @description Page template for 'homepage' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Home page React element.
 */
const Base = ({pageContext}) => {
  const {setSearchResultFilter, filterValue} = useHomeSearchHandles();
  return (
    <RCDLStateContext.Consumer>
      {RCDLState => {
        if (typeof window !== 'undefined') {
          return (
            <WithSearch
              mapContextToProps={({setSearchTerm, searchTerm, autocompletedResults}) => ({
                setSearchTerm,
                searchTerm,
                autocompletedResults
              })}
            >
              {({setSearchTerm, searchTerm, autocompletedResults}) => {
                return (
                  <PageWrapper pageContext={pageContext}>
                    <LayoutContainer
                      columns="one"
                      additionalClass={
                        'rc-max-width--xl rc-text--center rc-padding-top--xl--mobile rc-padding-top--md '
                      }
                    >
                      <Column additionalClass="rc-padding--md rc-margin-bottom--lg--mobile">
                        <h1 className="rc-alpha rc-margin-top--md">{pageContext.title}</h1>
                        <p className="rc-max-width--md rc-padding--sm rc-margin-bottom--none--desktop rc-padding-top--none--desktop">
                          {pageContext.summary.summary}
                        </p>
                        <div className="rc-btn-group rc-margin-bottom--md">
                          <Button buttonType="two" tag="link" href="/cat">
                            Cat owners insights
                          </Button>
                          <Button buttonType="two" tag="link" href="/dog">
                            Dog owners insights
                          </Button>
                        </div>
                      </Column>
                    </LayoutContainer>
                    <LayoutGenerator layouts={pageContext.layoutContainer} />
                    <Divider additionalClass="rc-margin-bottom--lg" />

                    <LayoutContainer
                      columns="one"
                      additionalClass="rc-max-width--xl rc-text--center
              rc-padding-bottom--md rc-padding-top--md rc-margin-bottom--sm"
                    >
                      <Column>
                        <h3 className="rc-beta rc-margin-top--md rc-margin-bottom--lg">Start your search</h3>
                        <SearchInputInline
                          setSearchTerm={setSearchTerm}
                          searchTerm={searchTerm}
                          liClasses="rc-padding--xs"
                          ulClasses="rc-list rc-list--align rc-list--blank rc-bg-colour--brand4 rc-padding--xs"
                          setFilter={setSearchResultFilter}
                          currentFilter={filterValue}
                          RCDLState={RCDLState}
                          autocompletedResults={autocompletedResults}
                        />
                        <div className="rc-margin-top--lg" />
                      </Column>
                    </LayoutContainer>
                  </PageWrapper>
                );
              }}
            </WithSearch>
          );
        }
        return null;
      }}
    </RCDLStateContext.Consumer>
  );
};

Base.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Base;
